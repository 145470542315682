<template>
  <div>
    <filter-view :loading="loading" @submit="toQuery">
      <dept-Filter :query="query" @init="toQuery" />
      <div class="c h sb sep-t">
        <div class="no-flex padding-10">仓库类型</div>
        <el-radio-group v-model="query.warehouseType" @change="toQuery">
          <el-radio :label="null">全部</el-radio>
          <template v-for="t in warehouseTypes">
            <el-radio :key="t.id" :label="t.id">{{t.name}}</el-radio>
          </template>
        </el-radio-group>
      </div>
    </filter-view>
    <div class="result">
      <div class="padding-10 h c no-flex fs-small fc-g">分析结果</div>
      <el-table ref="table" v-loading="loading" :data="reportDatas" height="200px" style="min-height: 6rem;" @sort-change="handleSortChange" row-key="id" border lazy :load="load" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column align="left" label="项目" width="120px;" show-overflow-tooltip fixed="left">
          <template slot-scope="scope">{{scope.row.purchase ? scope.row.purchase.name : scope.row.sale ? scope.row.sale.name : scope.row.warehouse ? scope.row.warehouse.name : "--"}}</template>
        </el-table-column>
        <el-table-column align="center" label="销售">
          <el-table-column align="center" prop="sale.money" label="含税金额（元）" :formatter="$price" width="160px;" sortable="custom" />
          <el-table-column align="center" prop="sale.moneyRatio" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.sale && scope.row.sale.moneyRatio ? (scope.row.sale.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="sale.amount" label="数量（件）" width="135px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.sale ? scope.row.sale.amount : 0)}}</template>
          </el-table-column>
          <el-table-column align="center" prop="sale.amountRatio" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.sale && scope.row.sale.amountRatio?(scope.row.sale.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="sale.avgMoney" label="含税均价" :formatter="$price" width="135px;" sortable="custom" />
        </el-table-column>

        <el-table-column align="center" label="采购">
          <el-table-column align="center" prop="purchase.money" label="含税金额（元）" :formatter="$price" width="160px;" sortable="custom" />
          <el-table-column align="center" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.purchase && scope.row.purchase.moneyRatio?(scope.row.purchase.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="purchase.amount" label="数量（件）" width="135px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.purchase ? scope.row.purchase.amount : 0)}}</template>
          </el-table-column>

          <el-table-column align="center" prop="purchase.amountRatio" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.purchase && scope.row.purchase.amountRatio?(scope.row.purchase.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="purchase.avgMoney" label="含税均价" :formatter="$price" width="135px;" sortable="custom" />
        </el-table-column>

        <el-table-column align="center" label="库存量">
          <el-table-column align="center" prop="warehouse.money" label="含税金额（元）" :formatter="$price" width="160px;" sortable="custom" />
          <el-table-column align="center" prop="warehouse.moneyRatio" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.warehouse && scope.row.warehouse.moneyRatio?(scope.row.warehouse.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.amount" label="数量（件）" width="135px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.warehouse ? scope.row.warehouse.amount : 0)}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.amountRatio" label="占比" width="135px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.warehouse && scope.row.warehouse.amountRatio?(scope.row.warehouse.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.avgMoney" label="含税均价" :formatter="$price" width="160px;" sortable="custom" />
        </el-table-column>
      </el-table>

      <div class="padding-10 h c no-flex" v-if="trendQuery">
        <div class="flex bold">
          {{new Date(trendQuery.begDate).format("yyyy-MM-dd")}}至{{new Date(trendQuery.endDate).format("yyyy-MM-dd")}}
          <br />进销存走势图（按周统计）
        </div>
        <el-radio-group v-model="trendType" style="padding: 0;" @change="drawTrend">
          <el-radio-button label="money">金额（万元）</el-radio-button>
          <el-radio-button label="amount">数量</el-radio-button>
        </el-radio-group>
      </div>
      <div ref="trendChart" class="no-flex" style="height: 220px;"></div>
    </div>
  </div>
</template>
<script>
import filterView from "../assembly/filter";
import deptFilter from "./deptSelect";
import request from "@/utils/request";
export default {
  name: "purchaseAll",
  components: { deptFilter, filterView },

  data() {
    let now = new Date();
    return {
      loading: false,
      downloadLoading: false,
      reportDatas: [],
      downloadDadas: [],

      trendData: [],
      trendQuery: null,
      trendType: "money",
      trendTypeMaps: {
        money: "金额",
        amount: "数量",
      },
      trendSeriesMaps: {
        purchase: "采购",
        sale: "销售",
        warehouse: "库存",
      },
      trendChart: null,

      showOrNotRatio: false,
      classIdOne: null,
      classIdTwo: null,
      classIdThree: null,
      params: null,
      currentSortColumn: null,
      warehouseTypes: [
        { id: 0, name: "中央库房" },
        { id: 1, name: "零售店" },
      ],
      query: {
        begDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() -
            6 -
            (new Date().getDay() == 0 ? 7 : new Date().getDay()),
          0,
          0,
          0
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - (new Date().getDay() == 0 ? 7 : new Date().getDay()),
          23,
          59,
          59
        ).getTime(),
        treeNodeId: null,
        treeNodeType: null,
        warehouseType: null,
        groupType: 0,
      },
    };
  },
  mounted() {
    this.toQuery();
  },
  methods: {
    makeParams() {
      let query = JSON.parse(JSON.stringify(this.query));
      query.sort = this.sort;
      query.begDate = new Date(query.begDate);
      query.endDate = new Date(query.endDate);
      query.begDate = new Date(
        query.begDate.getFullYear(),
        query.begDate.getMonth(),
        query.begDate.getDate(),
        0,
        0,
        0
      ).getTime();
      query.endDate = new Date(
        query.endDate.getFullYear(),
        query.endDate.getMonth(),
        query.endDate.getDate(),
        23,
        59,
        59
      ).getTime();
      return query;
    },
    toQuery() {
      if (this.query.treeNodeType && this.query.treeNodeId) {
        this.reportDatas = [];
        this.loading = true;
        let params = this.makeParams();
        request({
          url: "@host:analysis;api/analy/purchase/all",
          method: "get",
          params,
        })
          .then((res) => {
            let total = {
              id: this.$uuid(),
              _groupType: -1,
              hasChildren: false,
              sale: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
              purchase: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
              warehouse: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
            };
            res.forEach((o) => {
              o.id = this.$uuid();
              o._groupType = 1;
              o.hasChildren = true;
              o.children = [];
              if (o.sale) {
                total.sale.amount += o.sale.amount || 0;
                total.sale.money += o.sale.money || 0;
              }
              if (o.purchase) {
                total.purchase.amount += o.purchase.amount || 0;
                total.purchase.money += o.purchase.money || 0;
              }
              if (o.warehouse) {
                total.warehouse.amount += o.warehouse.amount || 0;
                total.warehouse.money += o.warehouse.money || 0;
              }
            });
            total.sale.avgMoney = Math.round(
              total.sale.money / total.sale.amount
            );
            total.purchase.avgMoney = Math.round(
              total.purchase.money / total.purchase.amount
            );
            total.warehouse.avgMoney = Math.round(
              total.warehouse.money / total.warehouse.amount
            );
            res.push(total);
            this.loading = false;
            this.reportDatas = res;
          })
          .catch((err) => {
            this.loading = false;
            this.reportDatas = [];
          })
          .finally(() => {
            this.loading = false;
            this.$refs.table && this.$refs.table.doLayout();
          });

        let q1 = Object.assign({}, params);
        let ed = new Date(q1.endDate);
        q1.begDate = new Date(
          ed.getFullYear(),
          ed.getMonth(),
          ed.getDate() - 55
        ).getTime();
        this.trendQuery = q1;
        request({
          url: "@host:analysis;api/analy/purchase/week/byTrend",
          method: "get",
          params: q1,
        }).then((res) => {
          this.trendData = res;
          this.drawTrend();
        });
      }
    },
    load(tree, treeNode, resolve) {
      let query = this.makeParams();
      if (tree._groupType == 1) {
        this.classIdOne =
          (tree.purchase && tree.purchase.id) ||
          (tree.sale && tree.sale.id) ||
          (tree.warehouse && tree.warehouse.id) ||
          "";
        this.params = Object.assign(
          {
            classId: this.classIdOne,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      } else if (tree._groupType == 2) {
        this.classIdTwo = tree.purchase
          ? tree.purchase.id
          : tree.sale
          ? tree.sale.id
          : tree.warehouse
          ? tree.warehouse.id
          : "";
        this.params = Object.assign(
          {
            classId: this.classIdOne,
            seriesId: this.classIdTwo,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      } else if (tree._groupType == 3) {
        this.classIdThree =
          (tree.purchase && tree.purchase.id) ||
          (tree.sale && tree.sale.id) ||
          (tree.warehouse && tree.warehouse.id) ||
          "";
        this.params = Object.assign(
          {
            spuId: this.classIdThree,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      }
      request({
        url: "@host:analysis;api/analy/purchase/all",
        method: "get",
        params: this.params,
      }).then((res) => {
        if (res.length > 0) {
          res.forEach((ry) => {
            ry.id = this.$uuid();
            ry._groupType = tree._groupType + 1;
            this.downloadDadas.push(ry);
            if (ry._groupType < 3) {
              ry.hasChildren = true;
              ry.children = [];
            }
          });
          resolve(res);
        } else {
          resolve([]);
        }
        this.$nextTick(() => {
          if (this.currentSortColumn) {
            this.handleSortChange(this.currentSortColumn);
          }
        });
      });
    },

    drawTrend() {
      if (!this.trendChart) {
        this.trendChart = echarts.init(this.$refs.trendChart, "light");
      }
      this.trendChart.clear();

      if (this.trendData && this.trendData.length) {
        let xData = [],
          series = [],
          s = {};
        this.trendData.forEach((o) => {
          for (let k in o) {
            if (!s.hasOwnProperty(k)) {
              s[k] = {
                type: "line",
                smooth: true,
                name:
                  this.trendSeriesMaps[k] + this.trendTypeMaps[this.trendType],
                data: [],
              };
              series.push(s[k]);
            }
            if (o[k]) {
              let d = o[k];
              if (xData.indexOf(d.name) < 0) xData.push(d.name);
              s[k].data.push({
                name: d.name,
                value:
                  this.trendType === "money"
                    ? Math.round(d.money / 10000) / 100
                    : Math.round(d[this.trendType]),
              });
            }
          }
        });

        this.trendChart.setOption({
          backgroundColor: "#ffffff",
          grid: {
            top: 50,
            bottom: 20,
            left: 20,
            right: 50,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            data: xData,
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            name: this.trendType === "money" ? "金额（万元）" : "数量",
          },
          series,
        });
      }
    },

    // 合计功能
    // getReports(param) {
    //   const { columns, data } = param;
    //   const sums = [],
    //     priceColums = [
    //       "sale.money",
    //       "sale.avgMoney",
    //       "purchase.money",
    //       "purchase.avgMoney",
    //       "warehouse.money",
    //       "warehouse.avgMoney",
    //     ],
    //     avgPriceColums = [
    //       "sale.moneyRatio",
    //       "sale.amountRatio",
    //       "purchase.moneyRatio",
    //       "purchase.amountRatio",
    //       "warehouse.moneyRatio",
    //       "warehouse.amountRatio",
    //     ],
    //     amountColums = ["sale.amount", "purchase.amount", "warehouse.amount"];

    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = "合计";
    //       return;
    //     }

    //     if (column && column.property) {
    //       let csum = 0,
    //         sumable = true;
    //       data.forEach((item) => {
    //         let ps = column.property.split("."),
    //           ov = item[ps[0]],
    //           inx = 1;
    //         while (ov && inx <= ps.length - 1) {
    //           ov = ov[ps[inx]];
    //           inx++;
    //         }
    //         if (typeof ov === "string") sumable = false;
    //         else {
    //           csum += ov || 0;
    //         }
    //       });
    //       if (sumable) {
    //         sums[index] =
    //           priceColums.indexOf(column.property) >= 0
    //             ? this.$price(csum)
    //             : avgPriceColums.indexOf(column.property) >= 0
    //             ? (csum * 100).toFixed(2) + "%"
    //             : amountColums.indexOf(column.property) >= 0
    //             ? csum.toFixed(2)
    //             : csum;
    //       }
    //     }
    //   });
    //   return sums;
    // },

    getDataVal(item, path) {
      let ps = path.split("."),
        ov = item[ps[0]],
        inx = 1;
      while (ov && inx <= ps.length - 1) {
        ov = ov[ps[inx]];
        inx++;
      }
      return ov;
    },
    handleSortChange(column) {
      if (column.order === "ascending" || column.order === "descending") {
        this.currentSortColumn = column;
        let store = this.$refs.table.store.states.lazyTreeNodeMap;
        for (let k in store) {
          store[k] = store[k].sort((a, b) => {
            let aval = this.getDataVal(a, column.prop),
              bval = this.getDataVal(b, column.prop);
            return (
              (aval < bval ? -1 : 1) * (column.order === "ascending" ? 1 : -1)
            );
          });
        }
      } else {
        this.currentSortColumn = null;
      }
    },

    getExportRowData(v, level) {
      let indent = "        ".substr(0, level * 2);
      return [
        indent +
          (v.sale
            ? v.sale.name
            : v.purchase
            ? v.purchase.name
            : v.warehouse
            ? v.warehouse.name
            : "--"),
        v.sale ? this.$price(v.sale.money) : "--",
        v.sale ? (v.sale.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.sale ? v.sale.amount.toFixed(2) : "--",
        v.sale ? (v.sale.amountRatio * 100).toFixed(2) + "%" : "--",
        v.sale ? this.$price(v.sale.avgMoney) : "--",
        v.purchase ? this.$price(v.purchase.money) : "--",
        v.purchase ? (v.purchase.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.purchase ? v.purchase.amount.toFixed(2) : "--",
        v.purchase ? (v.purchase.amountRatio * 100).toFixed(2) + "%" : "--",
        v.purchase ? this.$price(v.purchase.avgMoney) : "--",
        v.warehouse ? this.$price(v.warehouse.money) : "--",
        v.warehouse ? (v.warehouse.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.warehouse ? v.warehouse.amount.toFixed(2) : "--",
        v.warehouse ? (v.warehouse.amountRatio * 100).toFixed(2) + "%" : "--",
        v.warehouse ? this.$price(v.warehouse.avgMoney) : "--",
      ];
    },
    // 导出
    download() {
      this.downloadLoading = true;
      import("@/utils/export2Excel").then((excel) => {
        const tHeader = [
          "项目",
          "销售金额（元）",
          "销售金额占比",
          "销售数量",
          "销售数量占比",
          "销售均价",
          "采购金额（元）",
          "采购金额占比",
          "采购数量",
          "采购数量占比",
          "采购均价",
          "库存金额（元）",
          "库存金额占比",
          "库存数量",
          "库存数量占比",
          "库存均价",
        ];
        const data = [],
          store = this.$refs.table.store.states.lazyTreeNodeMap;
        let fn = (ds, level = 0) => {
          ds.forEach((d) => {
            data.push(this.getExportRowData(d, level));
            if (store.hasOwnProperty(d.id)) {
              fn(store[d.id], level + 1);
            }
          });
        };
        fn(this.reportDatas);
        excel.export_json_to_excel({
          header: tHeader,
          data: data,
          filename: "管理报告",
        });
        this.downloadLoading = false;
      });
    },
  },
};
</script>